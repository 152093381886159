import { useEffect, useState, createRef, } from 'react';
import { Col, Modal,  Row ,Button , Layout,Select,Typography , Input, Divider,Space,Spin,notification,message, Drawer} from 'antd';
import {  SwapOutlined } from '@ant-design/icons';
import { useAccount,useDisconnect, useConnect,useContract,useSigner } from 'wagmi'
import { Profile } from './profile';
import { CloseOutlined } from  '@ant-design/icons'
import { useNetwork,useSwitchNetwork  } from 'wagmi'
import ExchangeAbi from './exchanngeAbi.json';
import FinAbi from './finAbi.json';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { Alchemy, Network, Utils } from "alchemy-sdk";
import { LoadingOutlined } from '@ant-design/icons';

import "./home.css";




const { Header, Footer, Sider, Content } = Layout;
const { Text, Link } = Typography;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color:"#0A0519"
      
    }}
    spin
  />
);

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '12vh',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
  };

  const contentStyle = {
    minHeight: '70vh',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
    
    };



  const footerStyle = {
    height: '12vh',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
  };

  const config = {
    apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
    network: Network.MATIC_MAINNET,
  };
  const alchemy = new Alchemy(config);

function ExchangePage() {

    const [inputStyleFin, setInputStyleFin] = useState({textAlign:"start"});
    const [inputStyleMatic, setInputStyleMatic] = useState({textAlign:"end"});
    const [selectedCoin, setSelectedCoin] = useState("MATIC");
    const [selectedCoinRate, setSelectedCoinRate] = useState("25 FIN");
    const [selectedCoinRateFinity, setSelectedCoinRateFinity] = useState("0.04 MATIC");
    const [selectedCoinValue, setSelectedCoinValue] = useState();
    const [finValue, setFinValue] = useState();
    const [showWalletModal, setShowWalletModal] = useState(false);
    const { address, isConnected,  connector: activeConnector, } = useAccount();
    const { connect, connectors, error, isLoading, pendingConnector } =useConnect()
    const { chain } = useNetwork()
    const { chains, pendingChainId, switchNetwork } =
      useSwitchNetwork()
    const { data: signer, isError } = useSigner()
    const [api, contextHolder] = notification.useNotification();
  
    
    const [LoadingScreen, setLoadingScreen] = useState(false);
    const { disconnect } = useDisconnect();


    useEffect(()=>{
         console.log(address);
         if(address !== undefined){
          setShowWalletModal(false);

         }

    },[isConnected]);


    const exchangeContract = useContract({
      address: '0x4b7D60c182Baf0242cCB45547E72463a18D9B5F4',
      abi: ExchangeAbi,
      signerOrProvider: signer,
  
    })

    const finContract = useContract({
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      abi: FinAbi,
      signerOrProvider: signer,
  
    })
  
  const openNotificationBalance = (placement) => {
 
    api.error({
      message: 'Transaction Error',
      description:'Insufficient Fund',
      placement
    });
  };


    const openNotification = (placement) => {
 
      api.error({
        message: 'Transaction Error',
        description:'',
        placement
      });
    };

    const openNotificationComplete = (placement) => {
 
      api.success({
        message: 'Transaction Complete',
        description:'',
        placement
      });
    };
  /*
    const buyFin = async () => {
      console.log(selectedCoinValue);
 if(selectedCoinValue !== undefined){
  if ((await activeConnector.getChainId()) !== 137) {
    if (activeConnector.switchChain) {
      await activeConnector.switchChain(137);
    } else {
      openNotification('bottomLeft');
    }
  }

  if(chain.id == "137" ){
   

    let amount1 = selectedCoinValue.toString();
    

    let NFTprice = Web3.utils.toWei(amount1,'ether');
    //var NFTprice = ethers.utils.parseUnits(amount1, 18);
    console.log(NFTprice);

    if (selectedCoin === "WETH") {
      const approve = await finContract.approve("0xa906ED2b84A0Ad19757Dc883159E877ac0023e4B", NFTprice);
      setLoadingScreen(true);
      await approve.wait();
      setLoadingScreen(false);
       const tx = await exchangeContract.buyWithCrypto("0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa", NFTprice).then (async(result)=>  {
        checkTransactionStatus(result);
        
          }).catch (function (error){
    
            var message = error.data && error.data.message ;
            console.log(error);
            openNotification('bottomLeft');
        });
     await tx.wait();
    } else {
      
      const tx = await exchangeContract.buywithMatic({ value: NFTprice }).then (async(result)=>  {
        checkTransactionStatus(result);
        
          }).catch (function (error){
    
            var message = error.data && error.data.message ;
            console.log(error);
            openNotification('bottomLeft');
    
        });
      await tx.wait();
     
    }
  }
 }else{
  openNotification('bottomLeft');
 }
      
    
    }

*/
  

  
const buyFin = async () => {

  if(isConnected == true){

  
  console.log(selectedCoinValue);
if(selectedCoinValue !== undefined){
if ((await activeConnector.getChainId()) !== 137) {
if (activeConnector.switchChain) {
  await activeConnector.switchChain(137);
} else {
  openNotification('bottomLeft');
}
}

if(chain.id == "137" ){


let amount1 = selectedCoinValue.toString();


//let NFTprice = Web3.utils.toWei(amount1,'ether');
var NFTprice = ethers.utils.parseUnits(amount1, 18);
console.log(NFTprice);

if (selectedCoin === "WETH") {

  const data = await alchemy.core.getTokenBalances(
    address,
    ["0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"]
  );

  

  var balValue = Utils.formatEther(data.tokenBalances[0].tokenBalance);

  if(balValue > selectedCoinValue){

 

  const approve = await finContract.approve("0x4b7D60c182Baf0242cCB45547E72463a18D9B5F4", NFTprice);
  setLoadingScreen(true);
  await approve.wait();
  setLoadingScreen(false);
   const tx = await exchangeContract.buyWithCrypto("0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", NFTprice);
   setLoadingScreen(true);
   
 await tx.wait();
 setLoadingScreen(false);
 openNotificationComplete('bottomLeft');

  }else{
    openNotificationBalance('bottomLeft');
  }
  
  
} else {

  let maticBalance = await alchemy.core.getBalance(address, 'latest');
  maticBalance = Utils.formatEther(maticBalance);
  console.log(maticBalance);
  
  if(maticBalance > selectedCoinValue){

  
  const tx = await exchangeContract.buywithMatic({ value: NFTprice });
  setLoadingScreen(true);
  await tx.wait();
  setLoadingScreen(false);
  openNotificationComplete('bottomLeft');
}else{
  openNotificationBalance('bottomLeft');

}

}
}
}else{
openNotification('bottomLeft');
}
  
}else{
  setShowWalletModal(true);
}
}

  {/*
    const  checkTransactionStatus=async(result)=>{
    setLoadingScreen(true);
    const txReceipt = await alchemy.core.getTransactionReceipt(
    result.hash
   );
   
   if(txReceipt == null){
    setTimeout(checkTransactionStatus(result), 60);
   }else{
    if(txReceipt.status ==1){
      openNotificationComplete('bottomLeft');
      setLoadingScreen(false);
    }
    }
  }
*/}

    useEffect(()=>{
         console.log(address);

    },[isConnected]);


    const handleChange = (value) => {
        setSelectedCoin(value.key);
        if(value.key == "MATIC"){
        setSelectedCoinRate("25 FIN");
        setSelectedCoinRateFinity("0.04 MATIC");
        var finValue = 25*selectedCoinValue;
        if(selectedCoinValue > 0){
          setFinValue(finValue.toString());
        }

        }
        if(value.key == "WETH"){
          setSelectedCoinRate("40000 FIN");
          setSelectedCoinRateFinity("0.000025 WETH");
          var finValue = 40000*selectedCoinValue;
          if(selectedCoinValue > 0){
            setFinValue(finValue.toString());
          }
         
        }
        
      };

    const maticChange = (event) => {
        var value = event.target.value;
        setSelectedCoinValue(value);
        if(selectedCoin == "MATIC"){
          var finValue = 25*value;
          setFinValue(finValue.toString());
          var NewWidth  = 100+finValue.toString().length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px", fontSize:"32px"});

      }
        }
        
        if(selectedCoin == "WETH"){
          var finValue = 40000*value;
          setFinValue(finValue.toString());
          var NewWidth  = 100+finValue.toString().length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px", fontSize:"32px"});

      }
        }
        var NewfontSize  = 42-value.length*1.5;
        var NewfontSizePx  = NewfontSize.toString()+"px";

        var NewWidth  = 100+value.length*10;
        var NewWidthPx  = NewWidth.toString()+"px";

        if(NewWidth<300){
            setInputStyleMatic({width:NewWidthPx, textAlign:"end"});
        }else{
            setInputStyleMatic({width:"300px", fontSize:"32px"});

        }
       
      };  
    
    const finChange = (event) => {
        
      var value = event.target.value;
       setFinValue(value);
      if(selectedCoin == "MATIC"){
        var selectValue = 0.04*value;
        setSelectedCoinValue(selectValue.toString());

        var NewWidth  = 100+selectValue.toString().length*10;
        var NewWidthPx  = NewWidth.toString()+"px";

        if(NewWidth<300){
          setInputStyleMatic({width:NewWidthPx, textAlign:"end"});
      }else{
          setInputStyleMatic({width:"300px", fontSize:"32px"});

      }
      }
      
      if(selectedCoin == "WETH"){
        var selectValue = 0.000025*value;
        setSelectedCoinValue(selectValue.toString());

        var NewWidth  = 100+selectValue.toString().length*10;
        var NewWidthPx  = NewWidth.toString()+"px";

        if(NewWidth<300){
          setInputStyleMatic({width:NewWidthPx, textAlign:"end"});
      }else{
          setInputStyleMatic({width:"300px", fontSize:"32px"});

      }

      }


      var NewfontSize  = 42-value.length*1.5;
      var NewfontSizePx  = NewfontSize.toString()+"px";

      var NewWidth  = 100+value.length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px", fontSize:"32px"});

      }

      };    

       
const connectWallet = async()=>{
setShowWalletModal(true);
}

const disconnectWallet = async()=>{
  disconnect();

  }


      

  return (


<>

{contextHolder}

<Spin style={{marginTop:'15%', marginBottom:'15%'}} spinning={LoadingScreen} indicator={antIcon} delay={14} size={'large'}>

     <Modal 
     
       bodyStyle={{color:'white'}}
       closeIcon={<CloseOutlined style={{color:"#26F8FF"}}/>}
        open={showWalletModal}
        centered
        onOk={() => setShowWalletModal(false)}
        onCancel={() => setShowWalletModal(false)}
        footer={null}
         >
        <Profile/>
      </Modal>




<Layout>
      <Header style={headerStyle}>

      <Row >
      <Col flex={2} className="navBarLogo">

        <Space.Compact direction='horizontal'>
          
          <img src = "./logoicon.webp" style={{width:"64px"}}/>
<Text style={{fontFamily:"Audiowide", fontSize:"24px",marginLeft:"8px",marginTop:"5px", fontWeight:"200px"}}>Infinity Void</Text>
</Space.Compact>
      </Col>
      <Col flex={2} className="navBarButton">

        {isConnected == true?(

          <Space direction='horizontal'>
          <Text className="textWallet">
              {address.substring(0, 4)}....{address.slice(38)} 
          </Text>

<Button  onClick={()=>disconnectWallet()} style={{height:"40px", borderRadius:"20px"}}>Disconnect</Button>
</Space>


        ):(
<Button  onClick={()=>connectWallet()} style={{height:"40px", borderRadius:"20px"}}>Connect</Button>

        )}
      </Col>

    </Row>
      </Header> 
      <Content style={contentStyle}>
     
     <Content className='contentStyleMain'>

     <Content className='contentStyleMainChild'>

    
    
    <Text className='textHead'>Start a Trade</Text>     

    <Text className='textPara'>Select the amounts for your trade.</Text>     
   
   
     <Row  gutter={24} style={{marginTop:"10%",marginBottom:"10%"}}>
    <Col className="maticCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }} >
        
        <Space direction='vertical' size={"middle"}>
      <Text className='textParaHead' >You'll send</Text>
      <Space direction='horizontal'>
      <Select
      style={{fontSize:'42px'}}
      bordered={false}
    labelInValue
    defaultValue={{
      value: 'MATIC',
      label: 'MATIC',
    }}
    
    onChange={handleChange}
    options={[
      {
        value: 'WETH',
        label: 'WETH',
      },
      {
        value: 'MATIC',
        label: 'MATIC',
      },
    ]}
  />


<input onChange={maticChange} className='Input' placeholder="0.00" value={selectedCoinValue} style={inputStyleMatic}/>


</Space>


<Space direction='horizontal'>
  
<Text className='textPara'>1 {selectedCoin} =</Text>    
{selectedCoin == "MATIC"?(
 <input onChange={maticChange}  className='InputA' disabled placeholder={selectedCoinRate} style={{width:"65px", padding:"8px"}}/>
 ):(
<input onChange={maticChange}  className='InputA' disabled placeholder={selectedCoinRate} style={{width:"92px", padding:"8px"}}/>

)}


</Space>


      </Space>
      </Col>

     
      <Col className='midCol' flex={{ xs: 4, sm: 4, md: 4, lg: 4 }}  >
<SwapOutlined className='swapIcon'/>
      </Col>

      <Col className="finCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }} >
      <Space direction='vertical' size={"middle"}> 
      <Text className='textParaHead'> You'll receive *</Text>

      <Space direction='horizontal' size={'small'}>

      <input onChange={finChange} className='Input' placeholder="0.00" value={finValue} style={inputStyleFin}/>

      <Select
      style={{fontSize:'42px'}}
      disabled={true}
      showArrow={false}
      bordered={false}
    labelInValue
    defaultValue={{
      value: 'FINITY',
      label: 'FINITY',
    }}
    
    
  />



</Space>

<Space direction='horizontal'>
  
<Text className='textPara'>1 FINITY =</Text>    
{selectedCoin == "MATIC"?(
  <input onChange={maticChange}  className='InputB' disabled placeholder={selectedCoinRateFinity} style={{width:"98px",padding:"8px"}}/>
):(
<input onChange={maticChange}  className='InputB' disabled placeholder={selectedCoinRateFinity} style={{width:"135px",padding:"8px"}}/>

)}


</Space>

       </Space>
      
      </Col>
    </Row>


<Button onClick={()=>buyFin()} style={{height:"40px", borderRadius:"20px"}}>Execute Trade</Button>
<br/>
<Text className='textPara' style={{fontSize:"12px"}}>*Amount is inclusive of transaction fees of 2.5%.</Text> 

        </Content>
        </Content>

      </Content>
      <Footer style={footerStyle}>


      </Footer>
    </Layout>

    </Spin>

</>

  );
}

export default ExchangePage;
